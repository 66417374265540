
import { defineComponent, onMounted } from "vue";
import Quill from "quill/dist/quill.js";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "Widget1",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: {type: String, default: ""},
  },
  setup() {
    onMounted(() => {
      const editorId = "kt_forms_widget_1_editor";

      // init editor
      const options = {
        modules: {
          toolbar: {
            container: "#kt_forms_widget_1_editor_toolbar",
          },
        },
        theme: "snow",
      };

      // Init editor
      new Quill("#" + editorId, options);
    });
  },
});
