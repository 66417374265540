
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";

export default defineComponent({
  name: "Widget4",
  components: {
    Dropdown3,
  },
  props: {
    widgetClasses: {type: String, default: ""},
  },
});
