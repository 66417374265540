
import { defineComponent } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";

export default defineComponent({
  name: "Widget5",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: {type: String, default: ""},
  },
});
